import {createRouter, createWebHistory} from 'vue-router'  
import store from './store/index.js';
import { dataService } from "./shared/data.service";

// Uses route level code-splitting. This generates separate chunks (about.[hash].js)
// which are lazy-loaded when the route is visited.
const routes = [
    {
        path: '/',
        redirect: '/welcome',
        component: () => import(/* webpackChunkName: "bundle.welcome" */ './views/Welcome.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/welcome',
        name: 'welcome',
        component: () => import(/* webpackChunkName: "bundle.welcome" */ './views/Welcome.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: () => import(/* webpackChunkName: "bundle.forbidden" */ './views/Forbidden.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/',
        redirect: '/home',
        component: () => import(/* webpackChunkName: "bundle.home" */ './views/Home.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "bundle.home" */ './views/Home.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "bundle.users" */ './views/Users.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/companies',
        name: 'companies',
        component: () => import(/* webpackChunkName: "bundle.companies" */ './views/Companies.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/runs',
        name: 'runs',
        component: () => import(/* webpackChunkName: "bundle.runs" */ './views/Runs.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/analytics',
        name: 'analytics',
        component: () => import(/* webpackChunkName: "bundle.runs" */ './views/Analytics.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/docs',
        name: 'docs',
        component: () => import(/* webpackChunkName: "bundle.docs" */ './views/Docs.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "bundle.about" */ './views/About.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/callback',
        name: 'callback',
        component: () => import(/* webpackChunkName: "bundle.users" */ './views/Callback.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });

export default router;

router.beforeEach(async (to, from, next) => {

    // don't await the telemetry call - fire and forget is fine
    dataService.sendPageViewTelemetry(to.name);

    let user = store.state.user;

    if (user == null) {
        // Retrieve the user from the store
        user = await store.state.mgr.getUser();

        if (user != null) {
            await store.dispatch('setUserAction', user);
        }
    }

    let isAuthenticated = (user != null && !user.expired);
    let isAuthorised = (user != null && !user.profile.forbidden);

    if (to.path.startsWith('/callback')) {
        // Call returned from Veracity auth
        store.state.mgr.signinRedirectCallback().then(
            function (user) {
                var returnToUrl = "/home";

                if (user) {
                    dataService.getUser(user.profile.userId).then((userData) => {
                        // Add role to user's profile. This needs to be a separate call as Veracity doesn't handle roles
                        user.profile.role = userData.roleId;
                        user.profile.enabled = userData.enabled;
                        user.profile.companyId = userData.companyId;

                        sessionStorage.apiKey = userData.token;

                        // check if the users' company is disabled
                        dataService.getCompany(userData.companyId).then((companyData) => {
                            user.profile.companyEnabled = companyData.enabled;
                            store.state.mgr.storeUser(user);

                            dataService.sendEventTelemetry("Login", {
                                "Success": "true",
                                "UserEnabled": `${userData.enabled}`,
                                "CompanyEnabled": `${companyData.enabled}`,
                                "UserCompanyId": `${userData.companyId}`
                            });

                            // Save user details
                            store.dispatch('setUserAction', user).then(() => {
                                if (user.state !== undefined) {
                                    returnToUrl = user.state;
                                }

                                next(returnToUrl);
                            });
                        });
                    }).catch((error) => {

                        dataService.sendEventTelemetry("login", {
                            "success": "false",
                            "reason": `${error}`
                        });

                        // get user will throw a HTTP 403 forbidden exception if the user doesn't exist in the SF database
                        user.profile.forbidden = true;
                        store.state.mgr.storeUser(user);
                        next('/forbidden');
                    });
                }
            }
        );
    } else if (isAuthenticated && !isAuthorised && to.path.startsWith('/home')) {
        // Already signed but not a SF user,  switch from unauthenticated welcome page to forbidden
        next('/forbidden');
    } else if (isAuthenticated && to.path.startsWith('/welcome')) {
        // Already signed in switch from unauthenticated welcome page to home page
        next('/home');
    } else if (isAuthenticated && isAuthorised) {
        // Already signed in, we can navigate anywhere
        next();
    } else if (!to.meta.requiresAuth) {
        // No auth required
        next();
    } else {
        // Redirect user to sign in
        store.state.mgr.signinRedirect({ state: to.path });
    }
});
