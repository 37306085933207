import { createStore } from 'vuex'
import { security } from '../shared/security.ts';
import { UPDATE_CURRENT_USER, UPDATE_USER_MANAGER } from './mutation-types';

const state = {
  user: null,
  mgr: null
};

const mutations = {
  [UPDATE_CURRENT_USER](state, user) {
    state.user = user;
  },
  [UPDATE_USER_MANAGER](state, userManager) {
    state.mgr = userManager;
  }
};

const actions = {
  async setUserAction({ commit }, user) { 
    // Save user details
    commit(UPDATE_CURRENT_USER, user);
  },
  async setVeracityClientIdAction({ commit }, veracityClientId) {
    var userManager = security.createUserManager(veracityClientId);

    commit(UPDATE_USER_MANAGER, userManager);
  }
};

const modules = {};

const getters = {};

const store = createStore({
  state: state,
  mutations: mutations,
  actions: actions,
  modules: modules,
  getters: getters
});

export default store;
