<template>
  <div id="app">
    <Header />
    <router-view class="mt-4" id="mainPage" />
    <notifications position="top center" group="error">
      <template v-slot:body="props">
        <div class="alert alert-danger alert-dismissible" role="alert">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            @click="props.close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <i class="fal fa-exclamation-triangle warning pe-2"></i>
          <strong class="pe-2">Error!</strong> {{ props.item.text }}
        </div>
      </template>
    </notifications>
    <notifications position="top center" group="success">
      <template v-slot:body="props">
        <div class="alert alert-success alert-dismissible" role="alert">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            @click="props.close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <i class="fal fa-check-circle success pe-2"></i>
          <strong class="pe-2">Success!</strong> {{ props.item.text }}
        </div>
      </template>
    </notifications>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#mainPage {
  min-height: calc((100vh - 275px) - 116px);
  margin-left: 40px;
  margin-right: 40px;
}

/* Tooltip container */
.tooltipcontainer {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltipcontainer .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltipcontainer .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipcontainer:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.btn-close {
  background: none;
  font-size: 30px;
}

.alert-dismissible .btn-close {
  padding-top: 10px;
}
</style>
