<template>
<header class="o-header light">
  <nav class="o-header-left">
    <a href="/" class="logo logo-sm" target="_self">
      <img
          src="../assets/DNV_logo_RGB.svg"
          alt="DNV Logo"
          class="logo-image"
        />
    </a>
  </nav>
      <nav class="o-header-nav false">
        <ul
          class="o-header-links"
          id="o-header-links"
        >
          <li v-show="!isAuthenticated" id="Welcome" class="o-header-item">
            <router-link active-class="active" class="o-header-link" to="/welcome"
              >Home</router-link
            >
          </li>
          <li v-show="isAuthenticated" id="Home" class="o-header-item">
            <router-link active-class="active" class="o-header-link" to="/home"
              >Home</router-link
            >
          </li>
          <li
            id="Users"
            v-show="isAdmin && isAuthenticated && isEnabled"
            class="o-header-item"
          >
            <router-link active-class="active" class="o-header-link" to="/users"
              >Users</router-link
            >
          </li>
          <li
            id="Companies"
            v-show="isAdmin && isAuthenticated && isEnabled"
            class="o-header-item"
          >
            <router-link
              active-class="active"
              class="o-header-link"
              to="/companies"
              >Companies</router-link
            >
          </li>
          <li
            id="ModelChainRuns"
            v-show="isAuthenticated && isEnabled"
            class="o-header-item"
          >
            <router-link active-class="active" class="o-header-link" to="/runs"
              >Model chain runs</router-link
            >
          </li>
          <li
            id="Analytics"
            v-show="isAdmin && isAuthenticated && isEnabled"
            class="o-header-item"
          >
            <router-link active-class="active" class="o-header-link" to="/analytics"
              >Analytics</router-link
            >
          </li>
          <li
            id="Docs"
            v-show="isAuthenticated && isEnabled && !isForbidden"
            class="o-header-item"
          >
            <router-link active-class="active" class="o-header-link" to="/docs"
              >Docs</router-link
            >
          </li>
          <li id="About" class="o-header-item">
            <router-link active-class="active" class="o-header-link" to="/about"
              >About</router-link
            >
          </li>
        </ul>
      </nav>
      <div class="o-header-right">
        <nav class="o-header-name">
          <a href="/" class="o-header-product">SolarFarmer</a>
        </nav>
        <nav class="o-header-actions">
          <ul class="actions-group">
            <li v-show="!isAuthenticated" class="o-header-action">
              <a
                class="o-header-action-button o-header-sign-in-button"
                target="_self"
                href="#"
                @click="logIn()"
              >
                Log&nbsp;in
              </a>
            </li>
            <li 
              v-show="isAuthenticated"
              class="o-header-item has-children"
            >
              <a
                aria-haspopup="true"
                aria-expanded="false"
                target="_self"
                href="#"
                class="o-header-action-button"
                id="header-user-dropdown"
                @click="toggleDropdown()"
              >
                <section class="user-initials">
                  <p>{{ userInitials }}</p>
                </section>
              </a>
              <ul
                tabindex="-1"
                id="header-user-dropdown-menu"
                class="o-header-dropdown-menu child-menu"
                aria-labelledby="header-user-dropdown"
              >
                <li class="child-header">
                  <h5>Your profile</h5>
                </li>

                <li class="child-header">
                  <h4><i class="fal fa-user me-3"></i>{{ username }}</h4>
                </li>
                <li class="o-header-dropdown-divider">
                  <hr role="separator" aria-orientation="horizontal" />
                </li>
                <li class="child-header">
                  <h5>Manage Account</h5>
                </li>
                <li class="o-header-item">
                  <a
                    class="o-header-link"
                    href="https://services.veracity.com/EditProfile"
                    target="_blank"
                  >
                    <i class="fal fa-cog me-3"></i>
                    Settings
                  </a>
                </li>
                <li class="o-header-item">
                  <a href="#" class="o-header-link"
                  @click="logOut()"
                >
                    <i class="fal fa-user me-3"></i>
                      Log out
                    </a>
                </li>
              </ul>
            </li>
            <li class="o-header-item mobile">
              <button
                type="button"
                aria-label="Toggle navigation"
                aria-expanded="false"
                class="o-header-mobile-btn"
                data-toggle="o-header-links"
                id="o-header-mobile-btn"
              >
                <span class="btn-line"></span>
                <span class="btn-line"></span>
                <span class="btn-line"></span>
              </button>
            </li>
          </ul>
      </nav>
      </div>
      </header>
</template>

<script>
import { dataService } from "../shared/data.service";

export default {
  name: "Header",
  async created() {
    // Close user menu when clicking outside
    window.addEventListener("click", function (e) {
      const userMenu = document.getElementById("header-user-dropdown-menu");
      const userToggle = document.getElementById("header-user-dropdown");
      if (
        !userMenu.contains(e.target) &&
        !userToggle.contains(e.target) &&
        userMenu.classList.contains("show")
      ) {
        // Clicked in box
        document
          .getElementById("header-user-dropdown-menu")
          .classList.toggle("show");
      }
    });
  },
  methods: {
    // Handle toggle for user dropdown
    async toggleDropdown() {
      document
        .getElementById("header-user-dropdown-menu")
        .classList.toggle("show");
    },
    async logIn() {
      await this.$store.state.mgr.signinRedirect();
    },
    async logOut() {
      // we have to await the telemetry logout call otherwise it is never received 
      // as the page is disposed before the call is made
      await dataService.sendEventTelemetry("Logout", {
        Operation: "LogoutRequested",
      });
      await this.$store.state.mgr.signoutRedirect();
    },
  },
  computed: {
    // Current logged in user's name
    username() {
      const currentUser = this.$store.state.user;

      let username = "";

      if (currentUser && currentUser.profile) {
        username = currentUser.profile.name;
      }

      return username;
    },
    isAuthenticated() {
      const user = this.$store.state.user;
      return user != null && !user.expired;
    },
    isEnabled() {
      const user = this.$store.state.user;
      return (
        user != null && user.profile.enabled && user.profile.companyEnabled
      );
    },
    isForbidden() {
      const user = this.$store.state.user;
      return user != null && user.profile.forbidden;
    },
    isAdmin() {
      const user = this.$store.state.user;

      if (user == null || user.profile == null) {
        return false;
      }

      return user.profile.role == 1;
    },
    // Current logged in user's initials
    userInitials() {
      const currentUser = this.$store.state.user;

      let initials = "";

      if (currentUser && currentUser.profile) {
        initials =
          currentUser.profile.given_name.substring(0, 1) +
          currentUser.profile.family_name.substring(0, 1);
      }

      return initials;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>

<style>
#header-user-dropdown-menu {
  z-index: 4;
}
</style>
