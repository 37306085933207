import { createApp } from 'vue'
import App from './App.vue';
import router from './router';
import store from './store';
import 'swagger-ui/dist/swagger-ui.css';
import Notifications from '@kyvg/vue3-notification'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// Get config settings
fetch(`${process.env.VUE_APP_BACKEND_URL}/config`)
  .then(response => response.json())
  .then((data) => {
    // Set veracity client id and create user manager client
    store.dispatch('setVeracityClientIdAction', data.veracityClientId);
    
    const app = createApp(App);
      app.use(Notifications)
      .use(router)
      .use(store)
      .mount('#app');
  });
